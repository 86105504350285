import { useAuthStore } from '~~/stores/auth'

export default defineNuxtRouteMiddleware(async (to) => {
  const { isAuthenticated } = storeToRefs(useAuthStore())
  const { init } = useAuthStore()

  // Try to init auth session by refresh token cookie (if it exists).
  if (!isAuthenticated.value) {
    await init()
  }

  if (isAuthenticated.value) {
    return navigateTo((to.query.redirect as string) || '/')
  }
})
